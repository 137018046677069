import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { config } from 'config';
import { sentryReactSend } from 'utils/sentry/client';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { YANDEX_METRICS } from 'config/constants/counters';

import { YandexEvent } from 'utils/counters/YandexEvent';

import s from './styles.css';

const PUSHWOOSH_TIME_INITIALIZATION = 5000;

const selectRuntimeData = createSelector(
  [(state: IAppState) => state.runtime.isMobile],
  (isMobile) => ({
    isMobile,
  }),
);

function PushSubscribe() {
  const [pushWooshReady, setPushWooshReady] = useState(false);
  const [visible, setVisible] = useState(false);
  const { isMobile } = useSelector(selectRuntimeData);

  const closeForAWhile: (type: 'closed' | 'declined' | 'denied') => void = (
    type,
  ) => {
    try {
      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: `notification_push_custom_${type}`,
      });

      window.localStorage.setItem(
        'rh_pushLastShownTime',
        `${Number(new Date())}`,
      );
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }

    setVisible(false);
  };

  const init = () => {
    // По какой-то причине библиотека Pushwoosh крайне долго инициализируется
    if (!window.Pushwoosh) {
      setTimeout(() => init(), PUSHWOOSH_TIME_INITIALIZATION);

      return;
    }

    const Pushwoosh = window.Pushwoosh || [];

    Pushwoosh.push([
      'onSubscribe',
      () => {
        try {
          new YandexEvent(YANDEX_METRICS.COMMON).send({
            type: 'reachGoal',
            data: 'notification_push_custom_accepted',
          });

          window.localStorage.setItem('rh_pushSubscribed', '1');
        } catch (error) {
          sentryReactSend(error);
          console.error(`${new Date()} ${error}`);
        }

        setVisible(false);
      },
    ]);

    Pushwoosh.push([
      'onPermissionDenied',
      () => {
        closeForAWhile('denied'); // Скрыть на 7 дней
      },
    ]);

    Pushwoosh.push([
      'onReady',
      () => {
        const actions = [];
        const permissionPromise = Pushwoosh.driver.getPermission();
        const isSubscribedPromise = Pushwoosh.isSubscribed();

        actions.push(permissionPromise);
        actions.push(isSubscribedPromise);

        // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
        Promise.all(actions).then((result) => {
          const [permission, isSubscribed] = result;

          if (permission !== 'denied' && !isSubscribed) {
            // eslint-disable-next-line sonarjs/no-gratuitous-expressions
            setPushWooshReady(!isSubscribed);
          }
        });
      },
    ]);
  };

  useEffect(() => {
    const { navigator } = window;
    const isChromeBrowser =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isFirefox = /firefox/i.test(navigator.userAgent);

    const isShownForDesktop = !isMobile && (isChromeBrowser || isFirefox);
    const isShownForMobile = isMobile && (isChromeBrowser || isFirefox);

    if (isShownForDesktop || isShownForMobile) {
      init();

      const now = Number(new Date());
      const lastShown =
        window.localStorage.getItem('rh_pushLastShownTime') || 0;

      const subscribed = window.localStorage.getItem('rh_pushSubscribed') || 0;
      const canShowForm =
        now - config.PUSHWOOSH.PUSH_DELAY >= Number(lastShown);

      if (canShowForm && !Number(subscribed)) {
        setVisible(true);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (visible && pushWooshReady) {
      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: 'notification_push_custom_show',
      });
    }
  }, [visible, pushWooshReady]);

  if (!visible || !pushWooshReady) return null;

  const subscribe = () => {
    const Pushwoosh = window.Pushwoosh || [];

    try {
      Pushwoosh.subscribe();
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

  return (
    <div className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop)}>
      <div className={s.header}>
        <Typography variant="h3" component="span" className={s.title}>
          Привет!
        </Typography>
        <Icon
          id="close"
          className={s.close}
          onClick={() => closeForAWhile('closed')}
          {...getTop100Markup(isMobile, 'notification::push::closed')}
        />
      </div>
      <Typography variant="defaultMedium" className={s.subtitle}>
        Получайте уведомления об интересных статьях и гороскопах прямо в
        браузере
      </Typography>
      <div className={cn(s.buttons, isMobile && s.buttonsMobile)}>
        <Button
          type="primary"
          onClick={subscribe}
          className={cn(s.button, isMobile && s.buttonMobile, s.buttonYes)}
          {...getTop100Markup(isMobile, 'notification::push::accepted')}
        >
          Да, хочу
        </Button>
        <Button
          type="secondary"
          onClick={() => closeForAWhile('declined')}
          className={cn(s.button, isMobile && s.buttonMobile, s.buttonNo)}
          {...getTop100Markup(isMobile, 'notification::push::declined')}
        >
          Не сейчас
        </Button>
      </div>
    </div>
  );
}

export { PushSubscribe };
