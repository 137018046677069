import React, { useState } from 'react';
import cn from 'classnames';

import { InputDatetime } from '@rambler-components/input';

import { IInputTime } from './types';

import s from './styles.css';

/**
 * Инпут времени
 * @param value приходит в формате HH:mm:ss и выводится в формате HH:mm
 */
function InputTime({
  value = '',
  status,
  disabled,
  isMobile,
  className = '',
  placeholder = '',
  type = 'border',
  display = 'normal',
  onChange,
  onBlur,
  onFocus,
  ...rest
}: IInputTime) {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
  const [error, setError] = useState(false);

  const onKeyPress = (e: any) => {
    const { key } = e;
    const val = e.target.value.trim();

    // разрешаем ввод только цифр и двоеточия
    if (!/^[0-9:]+$/g.test(key)) {
      e.preventDefault();
    }

    // двоеточие идет только после цифр
    if (key === ':' && !/^\d+$/g.test(val)) {
      e.preventDefault();
    }
  };

  const onChangeTime = (time: string) => {
    setError(false);
    onChange(time);
  };

  const onErrorTime = (err: string) => {
    if (err) {
      setError(true);
    }
  };

  return (
    <div className={cn(s.root, isMobile && s.rootMobile)}>
      <InputDatetime
        className={className}
        placeholder={currentPlaceholder}
        type={type}
        display={display}
        inputType="time"
        value={value}
        disabled={disabled}
        status={error ? 'error' : status}
        onChangeDatetime={onChangeTime}
        onErrorDatetime={onErrorTime}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setCurrentPlaceholder(placeholder);

          if (onBlur) onBlur(e);
        }}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          setCurrentPlaceholder('Формат 00:00');

          if (onFocus) onFocus(e);
        }}
        onKeyPress={onKeyPress}
        spellCheck="false"
        {...rest}
      />
    </div>
  );
}

export default InputTime;
