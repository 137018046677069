import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IHumanDesignError {
  isMobile: boolean;
  className?: string;
}

function HumanDesignError({ isMobile, className }: IHumanDesignError) {
  const { top100Prefix } = useTop100Context();

  return (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      <Icon
        id="human-design-error"
        className={cn(isMobile ? s.errorMobile : s.errorDesktop)}
      />
      <h2 className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}>
        Кажется, что-то не так
      </h2>
      <span className={cn(s.text, isMobile ? s.textMobile : s.textDesktop)}>
        Мы уже работаем над тем, чтобы всё починить. А пока, вы можете почитать{' '}
        <Link
          className={s.link}
          to="/"
          href="/"
          {...getTop100Markup(isMobile, top100Prefix, 'horoscopes')}
        >
          гороскоп
        </Link>{' '}
        или проверить свои{' '}
        <Link
          className={s.link}
          to="/personal/"
          href="/personal/"
          {...getTop100Markup(isMobile, top100Prefix, 'personal')}
        >
          биоритмы
        </Link>
      </span>
      <Button
        className={cn(s.button, isMobile ? s.buttonMobile : s.buttonDesktop)}
        type="primary"
        onClick={() => window.location.reload()}
        {...getTop100Markup(isMobile, top100Prefix, 'reload_button')}
      >
        Обновить страницу
      </Button>
    </div>
  );
}

HumanDesignError.defaultProps = {
  className: '',
};

export { HumanDesignError };
