/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import _groupBy from 'lodash.groupby';

import { Link } from 'react-router-dom';

import { Image } from 'common/components/Card/Image';
import { Ad } from 'common/components/Ad';
import { Typography } from 'common/components/Typography';

import { getShortDescription } from 'common/utils/hdShortDescription';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IHumanDesignLines {
  lines?: HumanDesignType['lines'];
  profiles?: HumanDesignType['profiles'];
  isMobile: boolean;
  ads?: Record<number, string>;
  className?: string;
}

function HumanDesignLines({
  lines,
  profiles,
  isMobile,
  ads,
  className,
}: IHumanDesignLines) {
  const { top100Prefix } = useTop100Context();

  const limit = isMobile ? 1 : 2;
  const limitedCelebrities = useCallback(
    (celebrities) => celebrities.slice(0, limit),
    [limit],
  );
  const profilesByLines = useMemo(
    () => _groupBy(profiles, (profile) => profile.first_line_number),
    [profiles],
  );

  const img = useCallback(
    (profile: HumanDesignProfileType) => (
      <div
        className={cn(
          s.profileImageContainer,
          !isMobile
            ? s.profileImageContainerDesktop
            : s.profileImageContainerMobile,
        )}
      >
        <Image
          wrapperClassName={cn(
            s.profileImage,
            isMobile ? s.profileImageMobile : s.profileImageDesktop,
          )}
          image={{
            url: profile.image.url,
            s3: true,
          }}
          height={isMobile ? 160 : 140}
          width={isMobile ? 340 : 170}
          title={profile.title}
          isLazy={false}
          isMobile={isMobile}
        />
      </div>
    ),
    [isMobile],
  );

  if (!lines || !profilesByLines) return null;

  return (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      {Object.keys(profilesByLines).map((line, index) => {
        const currentLine = lines.find((val) => val.number === Number(line));

        if (!currentLine) return null;

        return (
          <div key={line}>
            <div
              className={cn(
                s.line,
                isMobile ? s.lineMobile : s.lineDesktop,
                index === 0 && s.lineFirst,
              )}
            >
              <h2
                className={cn(
                  s.title,
                  isMobile ? s.titleMobile : s.titleDesktop,
                )}
              >
                {line} линия — {currentLine.title}
              </h2>
              <Typography
                variant="defaultRegular"
                component="div"
                className={cn(
                  s.lineText,
                  isMobile ? s.lineTextMobile : s.lineTextDesktop,
                )}
              >
                {currentLine.short_description}
              </Typography>
              <div
                className={cn(s.list, isMobile ? s.listMobile : s.listDesktop)}
              >
                {profilesByLines[line].map((profile, profileIndex) => {
                  const key = `HumanDesignLines-${profile.slug}`;
                  const { celebrities } = profile;
                  const currentAdIndex = index * 2 + profileIndex;

                  return (
                    <>
                      <Link
                        className={cn(
                          s.profile,
                          isMobile ? s.profileMobile : s.profileDesktop,
                        )}
                        to={`/dizain-cheloveka/profili/${profile.slug}/`}
                        key={key}
                        {...getTop100Markup(
                          isMobile,
                          top100Prefix,
                          `interlinked_block::profile::${profile.slug}`,
                        )}
                      >
                        {!isMobile && img(profile)}
                        <div>
                          <Typography
                            variant="h3"
                            className={cn(
                              s.profileTitle,
                              isMobile
                                ? s.profileTitleMobile
                                : s.profileTitleDesktop,
                            )}
                          >
                            {profile.slug.replace('-', '/')} {profile.title}
                          </Typography>
                          {isMobile && img(profile)}
                          <Typography
                            variant="defaultMedium"
                            component="div"
                            className={cn(
                              s.profileDescription,
                              isMobile
                                ? s.profileDescriptionMobile
                                : s.profileDescriptionDesktop,
                            )}
                          >
                            {getShortDescription(profile.short_description)}
                          </Typography>
                          <Typography
                            variant="defaultMedium"
                            component="div"
                            className={cn(
                              s.profileCelebs,
                              isMobile
                                ? s.profileCelebsMobile
                                : s.profileCelebsDesktop,
                            )}
                          >
                            {limitedCelebrities(celebrities).map(
                              (
                                celebrity: HumanDesignCelebrityType,
                                celebIndex: number,
                              ) => (
                                <span key={celebrity.name}>
                                  {celebrity.name}
                                  {celebIndex === limit - 1
                                    ? ' и другие'
                                    : ', '}
                                </span>
                              ),
                            )}
                          </Typography>
                        </div>
                      </Link>
                      {isMobile && ads && ads[currentAdIndex] && (
                        <Ad
                          name={ads[currentAdIndex]}
                          wrapperClassName={s.adMobile}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            {!isMobile && ads && ads[index] && (
              <Ad name={ads[index]} wrapperClassName={s.ad} />
            )}
          </div>
        );
      })}
    </div>
  );
}

HumanDesignLines.defaultProps = {
  className: '',
  ads: {},
  lines: undefined,
  profiles: undefined,
};

export { HumanDesignLines };
