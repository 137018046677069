import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Button from '@rambler-components/button';
import { ThemeProvider } from '@rambler-components/theme';

import { getCards } from 'config/constants/news-letter';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { YandexEvent } from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';

import { Icon } from 'common/components/Icon';
import PreviewPopup from './PreviewPopup';

import s from './index.css';

interface ISubscribeCards {
  selected: string[];
  onSelect: (selectId: string) => void;
}

function SubscribeCards({ selected, onSelect }: ISubscribeCards) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const { newsLetterCrmKeys } = useSelector(
    (state: IAppState) => state.runtime.config,
  );

  const [preview, setPreview] = useState('');

  const theme = {
    button: {
      white: {
        color: '#315efb',
      },
    },
  };

  const { top100Prefix } = useTop100Context();

  const subscribeOnSelect = (id: string) => {
    onSelect(id);
    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: 'click_page_subscription',
    });
  };

  const cards = getCards(newsLetterCrmKeys);

  return (
    <div className={cn(s.root, isMobile && s.mobile)}>
      {cards.map(({ id, header, title, text }) => (
        <div key={id} className={s.card}>
          <div className={s.header}>
            <Icon id={header.icon} className={s.icon} />
            {header.text}
          </div>
          <h3 className={s.title}>{title}</h3>
          <p className={s.text}>{text}</p>
          <div className={s.buttons}>
            <Button
              type={selected.includes(id) ? 'secondary' : 'primary'}
              className={s.chooseButton}
              onClick={() => subscribeOnSelect(id)}
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                `subscribe_card::${id}::select_button${selected.includes(id) ? '::select' : ''}`,
              )}
            >
              {selected.includes(id) ? 'Выбрано' : 'Выбрать'}
            </Button>
            <ThemeProvider theme={theme}>
              <Button
                type="white"
                onClick={() => setPreview(id)}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  `subscribe_card::${id}::preview_button`,
                )}
              >
                Показать пример
              </Button>
            </ThemeProvider>
          </div>
        </div>
      ))}
      <PreviewPopup
        preview={cards.find(({ id }) => id === preview)}
        isSelected={Boolean(selected.find((id) => id === preview))}
        isOpen={Boolean(preview)}
        onClose={() => setPreview('')}
        onSelect={onSelect}
      />
    </div>
  );
}

export default memo(SubscribeCards);
