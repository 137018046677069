import { Organization, ImageObject } from 'schema-dts';

/**
 * Получение publisher кластера для schema.org
 *
 * @params name - имя проекта ("Рамблер/новости", "Рамблер/авто", ...)
 * @params alias - alias проекта
 * @params domain - доменное имя
 *
 * @returns - блок schema.org описывающий автора кластера
 */
export const getOrganization = (): Organization => ({
  '@type': 'Organization',
  name: 'Рамблер/гороскопы',
  logo: {
    '@type': 'ImageObject',
    url: 'https://horoscopes.rambler.ru/logo.png',
    width: '386 px', // Да, это валидно https://schema.org/Distance
    height: '60 px', // Да, это валидно https://schema.org/Distance
  },
});

/**
 * Получение ImageObject для schema.org
 *
 * @params imgUrl - url изображения
 * @params name - длиный заголовок кластера
 * @params description - описание слайда или длинный заголовок кластера
 * @params author - название источника
 * @params isMain - указывает что слайд главный на странице
 *
 * @returns - блок schema.org описывающий ImageObject
 */
export const getImageObject = ({
  imgUrl,
  name,
  description,
  author,
  isMain = true,
}: {
  imgUrl: string;
  name: string;
  description: string;
  author: string;
  isMain: boolean;
}): ImageObject => ({
  '@type': 'ImageObject',
  name,
  representativeOfPage: isMain,
  url: imgUrl,
  // @ts-expect-error: Type 'number' is not assignable to type 'SchemaValue<IdReference | Distance | QuantitativeValueLeaf, "width"> | undefined'
  width: 640,
  // @ts-expect-error: Type 'number' is not assignable to type 'SchemaValue<IdReference | Distance | QuantitativeValueLeaf, "height"> | undefined'
  height: 360,
  contentUrl: imgUrl,
  description,
  ...(author
    ? {
        author: {
          '@type': 'Thing',
          name: author,
        },
      }
    : {}),
});
