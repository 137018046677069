import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Ad } from 'common/components/Ad';

import { useRcmWidget } from 'common/hooks/useRcmWidget';

import s from './styles.css';

interface PropsType {
  className?: string;
}

/**
 * Виджет реккомендов.
 *
 * @param className - класс, если нужна обертка вокруг виджета;
 */
export const RcmWidget: React.FC<PropsType> = React.memo(({ className }) => {
  const isMobile = useSelector((state) => state.runtime.isMobile);

  const rcmwContainerRefFirst = useRcmWidget();

  return (
    <div className={cn(s.root, isMobile ? s.mobile : s.desktop, className)}>
      {isMobile && <Ad name="listing_spec" wrapperClassName={s.adMobile} />}

      <div ref={rcmwContainerRefFirst as any} />

      {!isMobile && <Ad name="native3" wrapperClassName={s.adDesktop} />}
    </div>
  );
});

RcmWidget.defaultProps = {
  className: '',
};

RcmWidget.displayName = 'RcmWidget';
