import React from 'react';

import Popup from '@rambler-components/popup';

import Content from './Content';

interface ISubscribeSuccess {
  isOpen: boolean;
  isOnlyContent?: boolean;
  onClose: () => void;
}

function SubscribeSuccess({
  isOpen,
  isOnlyContent,
  onClose,
}: ISubscribeSuccess) {
  // на мобилке в панели подписки
  // встраивается в уже открывшуюся всплывашку
  if (isOnlyContent && isOpen) {
    return <Content onClose={onClose} withCloseButton />;
  }

  return (
    <Popup width={370} zIndex={5005} isOpen={isOpen} onClose={onClose}>
      <Content withCloseButton={isOnlyContent} onClose={onClose} />
    </Popup>
  );
}

SubscribeSuccess.defaultProps = {
  isOnlyContent: false,
};

export default SubscribeSuccess;
