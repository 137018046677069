/* eslint-disable no-console */

import { captureException } from '@sentry/react';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { createSelector } from 'reselect';

import { RCM_ID } from 'config/constants/counters';

/**
 * Добавление функции в очередь виджета рекомендов
 *
 * @param callback - добавляемая функция
 */
const addCommand = (callback: () => void) => {
  window.rcmw.cmd.push(callback);
};

type UseRcmWidgetType = () => React.RefObject<HTMLDivElement | undefined>;

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (currentPage, currentSection, isMobile) => ({
    currentPage,
    currentSection,
    isMobile,
  }),
);

const selectPage = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  (page) => ({
    page,
  }),
);

const selectOptionsData = createSelector(
  [
    (state: IAppState) => state.runtime.ruid,
    (state: IAppState) => state.runtime.userId,
    (state: IAppState) => state.runtime.puid6,
    (state: IAppState) => state.runtime.puid15,
    (state: IAppState) => state.runtime.puid18,
    (state: IAppState) => state.runtime.puid48,
  ],
  (
    ruid,
    userId,
    puid6,
    puid15,
    puid18,
    puid48,
    // eslint-disable-next-line max-params
  ) => ({
    ruid,
    userId,
    puid6,
    puid15,
    puid18,
    puid48,
  }),
);

/**
 ** Избегаем повторной инициализации до обновления данных в redux.
 ** Будет работать только при условии одого единственного компонента <RcmWidget /> на странице
 ** P.S. хранение в useRef() тут не поможет
 */
let RCM_SETTINGS: Partial<Rcmw.IUserBlockSettings> = {};

/**
 * Хук инициализации виджета рекомендов
 * @see https://recsys.pages.rambler-co.ru/recommender/docs/index.html#/widget/integration
 *
 * @param clusterId - id кластера, для которого получаем рекомменды;
 */
export const useRcmWidget: UseRcmWidgetType = () => {
  const { currentPage, currentSection, isMobile } = useSelector(selectData);
  const { page } = useSelector((state) =>
    selectPage(state, currentPage, currentSection),
  );
  const { ruid, userId, puid6, puid15, puid18, puid48 } =
    useSelector(selectOptionsData);

  const rcmBlockRef = useRef<HTMLDivElement | undefined>(undefined);
  const clusterId = page.content?.article?.id
    ? `${page.content.article.id}`
    : undefined;

  useEffect(() => {
    // Для предотвращения вызова до установки userId в Topline
    if (userId === '') return;

    if (!window.rcmw) {
      // Добавление скрипта
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.rcmw = window.rcmw || { cmd: [] };

      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://rcmjs.rambler.ru/static/rcmw/rcmw.js';

      document.head.appendChild(script);
    }

    const templateName = (isMobile ? 'v3' : 'v4') as Rcmw.Template;
    const layoutType = (
      isMobile ? 'horizontal' : 'vertical'
    ) as Rcmw.WidgetLayoutType;

    const node = rcmBlockRef.current;

    const settings: Rcmw.IUserBlockSettings = {
      rcmId: RCM_ID,
      blockId: isMobile
        ? '3563fccf4f24411fabfe6001dc01c48d'
        : '66c74149b1394a558b23d86a4c98a598',
      templateName,
      layoutType,
      userId,
      xuid: ruid,
      contextItemId: clusterId || null,
      ads: {
        enabled: true,
        jparams: {
          puid6,
          puid15,
          puid18,
          puid48,
        },
      },
    };

    // Инициализация блока
    addCommand(() => {
      if (equal(RCM_SETTINGS, settings)) return;

      RCM_SETTINGS = { ...settings };

      const settingsWithNode = {
        ...settings,
        node,
      };

      // eslint-disable-next-line promise/prefer-await-to-then
      window.rcmw.initBlock(settingsWithNode).catch((error: unknown) => {
        console.log('🚀 ~ addCommand ~ error:', error);
        captureException(error);
      });
    });

    return () => {
      if (!node) return;

      // Удаление блока
      addCommand(() => {
        window.rcmw.createSessionId();

        if (node) {
          window.rcmw.removeBlockFromContainer(node);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, ruid, clusterId, puid6, puid15, puid18, puid48]);

  return rcmBlockRef;
};
