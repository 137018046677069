import React from 'react';
import { useSelector } from 'react-redux';

import Popup from '@rambler-components/popup';

import { SubscribeTypes } from 'config/constants/news-letter';
import { PopupContent } from './PopupContent';

interface ISubscribePopup {
  type?: SubscribeTypes;
  isOpen: boolean;
  onClose: () => void;
}

const DESKTOP_POPUP_WIDTH = 390;

function SubscribePopup({ type, isOpen, onClose }: ISubscribePopup) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  return (
    <Popup
      width={isMobile ? '100%' : DESKTOP_POPUP_WIDTH}
      zIndex={5005}
      isOpen={isOpen}
      onClose={onClose}
      closeOnClickOutside={!isMobile}
    >
      <PopupContent type={type as SubscribeTypes} onClose={onClose} />
    </Popup>
  );
}

SubscribePopup.defaultProps = {
  type: SubscribeTypes.DAILY_COMMON,
};

export default SubscribePopup;
