import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  PROJECT_KEY,
  getWithArgsSing,
  getWithArgsBirthdate,
} from 'config/constants/news-letter';

import { fetchCurrentLocation } from 'common/utils/fetchCurrentLocation';

import { getSignByDate } from 'common/utils/signByDate';

const RESPONSE_STATUS_ERR = 'err';
const RESPONSE_STATUS_OK = 'ok';
const DEFAULT_TIMEZONE = 'Europe/Moscow';

interface SubscribeDataType {
  email: string;
  birthday: string;
  apiSubscription: string;
  subscribeKeys: string[];
}

export const useNewsLetterSubscribe = (): {
  onFetchSubscribe: (
    data: SubscribeDataType,
  ) => Promise<{ ok: boolean; err: boolean }>;
  isLoading: boolean;
} => {
  const ruid = useSelector((state: IAppState) => state.runtime.ruid);
  const { newsLetterCrmKeys } = useSelector(
    (state: IAppState) => state.runtime.config,
  );

  const [isLoading, setIsLoading] = useState(false);

  const fetchSubscribe = async ({
    email,
    birthday,
    apiSubscription,
    subscribeKey,
    timezone = DEFAULT_TIMEZONE,
  }: Omit<SubscribeDataType, 'subscribeKeys'> & {
    subscribeKey: string;
    timezone?: string;
  }) => {
    try {
      const args: any = {};

      if (getWithArgsSing(newsLetterCrmKeys).includes(subscribeKey)) {
        const sing = getSignByDate(birthday) || '';

        args.zodiac_sign = sing;
      }

      if (getWithArgsBirthdate(newsLetterCrmKeys).includes(subscribeKey)) {
        args.birthdate = birthday;
      }

      const data = {
        key: `${PROJECT_KEY}:${subscribeKey}`,
        email,
        args,
        ruid,
        timezone_name: timezone,
      };
      // https://confluence.rambler-co.ru/pages/viewpage.action?pageId=35684697
      const response = await fetch(`${apiSubscription}/subscribe/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (!responseData?.result) {
        return RESPONSE_STATUS_ERR;
      }

      return RESPONSE_STATUS_OK;
    } catch (error) {
      console.error(error);

      return RESPONSE_STATUS_ERR;
    }
  };

  const onFetchSubscribe = async ({
    email,
    birthday,
    apiSubscription,
    subscribeKeys,
  }: SubscribeDataType) => {
    setIsLoading(true);

    const geo = await fetchCurrentLocation();

    const results = await Promise.all(
      subscribeKeys.map((key) =>
        fetchSubscribe({
          email,
          birthday,
          apiSubscription,
          subscribeKey: key,
          timezone: geo.timezone,
        }),
      ),
    );

    // если одна из подписок отвалилась - возвращаем ошибку
    const isFailed = results.find(
      (status: string) => status === RESPONSE_STATUS_ERR,
    );
    const result = {
      ok: false,
      err: false,
    };

    if (!isFailed) {
      result.ok = true;
    }

    setIsLoading(false);

    return result;
  };

  return {
    onFetchSubscribe,
    isLoading,
  };
};
