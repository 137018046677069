import { createContext, useContext } from 'react';

interface ContextType {
  isButtonClicked: boolean;
  setIsButtonClicked: (i: boolean) => void;
}

export const DateByDeviceContext = createContext<ContextType>({
  isButtonClicked: false,
  setIsButtonClicked: () => {},
});

export const useDateByDeviceContext = () => useContext(DateByDeviceContext);
