import React, { useState, useEffect, useRef } from 'react';
import { matchRoutes } from 'react-router-config';

import { useDispatch, useStore, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Switch } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useIncrementReloadKey } from 'common/hooks/incrementReloadKey';
import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { updateCounters } from 'utils/counters/updater';
import { brandingCleaner } from 'utils/brandingCleaner';

import { IAppRoute } from 'typings/AppRoute';
import type { AppStore } from 'common/redux/configure';

import { APP_VERSION } from 'config/constants';

interface PropsType {
  appRoutes: IAppRoute[];
  children: React.ReactNode[];
}

export function PageSwitch({ appRoutes, children }: PropsType) {
  const isInitialRender = useRef(true);
  const history = useHistory();
  const location = useLocation<{
    freeze?: boolean;
    disableAdUpdate?: boolean;
    loading?: boolean;
  }>();
  const dispatch = useDispatch();
  const store = useStore();
  const { isMobile, isUpdateFeedVisible, onboardingShown, isBranding } =
    useSelector((state: IAppState) => state.runtime);

  const [currentLocation, setCurrentLocation] = useState(location);
  const incrementReloadKey = useIncrementReloadKey();

  useEffect(() => {
    const [{ route, match }] = matchRoutes(appRoutes, location.pathname);

    if (route.redirect) {
      setCurrentLocation(location);

      return;
    }

    const isFreeze = location.state?.freeze ?? false;
    const isDisableAdUpdate = location.state?.disableAdUpdate ?? false;

    const unblockHistory = history.block(() => false);
    const navigate = async () => {
      if (!isInitialRender.current) {
        if (isFreeze !== true) {
          window.scrollTo(0, 0);
        }

        if (isBranding) {
          await dispatch({
            type: SET_RUNTIME_VARIABLE,
            name: 'isBranding',
            value: false,
          });
          brandingCleaner();
        }

        if (!isDisableAdUpdate) {
          incrementReloadKey();
        }

        updateCounters(APP_VERSION[isMobile ? 'MOBILE' : 'DESKTOP']);

        if (isUpdateFeedVisible) {
          await dispatch({
            type: SET_RUNTIME_VARIABLE,
            name: 'isUpdateFeedVisible',
            value: false,
          });
        }

        if (!onboardingShown && Cookies.get('onboardingShown') === 'true') {
          await dispatch({
            type: SET_RUNTIME_VARIABLE,
            name: 'onboardingShown',
            value: true,
          });
        }
      }

      setCurrentLocation(location);
      unblockHistory();
    };

    const changeNavigate = async () => {
      await navigate();

      const isClientInitialRender = __CLIENT__ && isInitialRender.current;

      if (typeof route.fetchData === 'function' && !isClientInitialRender) {
        await route.fetchData(store as AppStore, match);
      }

      setCurrentLocation({ ...location, state: { loading: false } });
    };

    changeNavigate();

    isInitialRender.current = false;
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Switch location={currentLocation}>{children}</Switch>;
}
