import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Likes } from 'common/components/Card/Likes';
import {
  CARD_NUMBERS_BY_TYPE,
  CODENAMES_BY_TYPE,
  FORTUNE_LINKS_BY_TYPES,
  FORTUNE_TITLES_BY_TYPES,
  FortuneTypes,
} from 'config/constants/tarot';
import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './styles.css';

interface IFortunePreviewProps {
  type: FortuneTypes;
  isMobile: boolean;
}

function FortunePreview({ type, isMobile }: IFortunePreviewProps) {
  const { top100Prefix } = useTop100Context();

  return (
    <Link
      to={FORTUNE_LINKS_BY_TYPES[type]}
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        s[`root-${type}`],
      )}
      {...getTop100Markup(
        isMobile,
        top100Prefix,
        `content_card::${FORTUNE_LINKS_BY_TYPES[type]}`,
      )}
    >
      <div className={cn(s.tarotCard, s['tarotCard-back'], s.tarotCardRight)} />
      <div className={cn(s.tarotCard, s['tarotCard-back'], s.tarotCardLeft)} />
      <div
        className={cn(
          s.tarotCard,
          s.tarotCardFront,
          s[`tarotCard-${CARD_NUMBERS_BY_TYPE[type]}`],
        )}
      />

      <div className={cn(s.symbol, s[`symbol-${type}`])} />
      <div className={cn(s.planet, s[`planet-${type}`])} />
      <div className={cn(s.star, s.starSmall)} />

      <div className={s.fade} />

      <span className={s.game}>Игра</span>
      <h2 className={s.title}>{FORTUNE_TITLES_BY_TYPES[type]}</h2>
      <Likes
        className={s.likes}
        likes={{ codename: CODENAMES_BY_TYPE[type], hash: '' }}
        visibleSignsCount={2}
      />
    </Link>
  );
}

export { FortunePreview };
