import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Image from '@rambler-components/image';
import { MediaRawDraftContentState } from 'typings/rnews.Cluster';

import { DraftText } from 'common/components/DraftText';
import { Typography } from 'common/components/Typography';
import { getCurrentTarot } from 'config/constants/tarot';
import { parseDraft } from 'common/utils/draftParser';

import s from './styles.css';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.origin],
  (origin) => ({
    origin,
  }),
);

interface ICardTarotTodayProps {
  cardId: number;
  draft: MediaRawDraftContentState;
  isMobile: boolean;
}

function TarotToday({ isMobile, cardId, draft }: ICardTarotTodayProps) {
  const { origin } = useSelector(selectData);

  if (!cardId) return null;

  const currentCard = getCurrentTarot('id', cardId);
  const cardUrl = `${origin}/tarot/${cardId}.svg`;
  const title = `Карта Таро сегодня: ${currentCard.name || ''}`;

  const draftParsed = parseDraft({
    draftStateInRow: draft,
    id: 'TarotTodayBlock',
    isMobile,
  });

  return (
    <>
      <Typography variant="h1" className={s.title}>
        Карты таро
      </Typography>
      <Typography variant="h2" className={s.subTitle}>
        {title}
      </Typography>
      <div className={s.imageWrapper}>
        <div
          className={cn(
            s.tarotCard,
            s['tarotCard-backStars'],
            s.back,
            s.backLeft,
          )}
        />

        <div
          className={cn(
            s.tarotCard,
            s['tarotCard-backStars'],
            s.back,
            s.backRight,
          )}
        />

        <Image isImg alt={currentCard.name} className={s.image} src={cardUrl} />
      </div>
      <DraftText
        data={draftParsed}
        title={currentCard.name}
        className={cn(
          s.draftText,
          isMobile ? s.draftTextMobile : s.draftTextDesktop,
        )}
        isMainCard
      />
    </>
  );
}

export { TarotToday };
