import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [(state: IAppState) => state.runtime.currentParams.humanDesignDetailed],
  (humanDesignDetailed) => ({
    humanDesignDetailed,
  }),
);

interface IHumanDesignBubbles {
  list?: HumanDesignType['types'] | HumanDesignType['profiles'];
  type?: string;
  isMobile: boolean;
  className?: string;
}

function HumanDesignBubbles({
  list,
  type,
  isMobile,
  className,
}: IHumanDesignBubbles) {
  const { humanDesignDetailed } = useSelector(selectRuntimeData);
  const { top100Prefix } = useTop100Context();

  if (!list || !list.length) return null;

  const renderList = () => (
    <div className={s.bubbles}>
      {list.map((item, index) => {
        const { title, slug } = item;
        const key = `${slug}-${index}`;

        if (slug === humanDesignDetailed) {
          return (
            <div
              className={cn(s.bubbleWrapper, s[`bubbleWrapper-${type}`])}
              key={key}
            >
              <Typography
                variant="defaultMedium"
                className={cn(
                  s.link,
                  s.defaultBubble,
                  !isMobile && s.linkDesktop,
                  s.linkActive,
                )}
              >
                {type === 'types' ? '' : slug.replace('-', '/')} {title}
              </Typography>
            </div>
          );
        }

        return (
          <div
            className={cn(s.bubbleWrapper, s[`bubbleWrapper-${type}`])}
            key={key}
          >
            <Typography
              variant="defaultMedium"
              className={cn(
                s.link,
                s.defaultBubble,
                !isMobile && s.linkDesktop,
              )}
              element={
                <Link
                  to={`/dizain-cheloveka/${type === 'types' ? 'tipy' : 'profili'}/${slug}/`}
                  {...getTop100Markup(
                    isMobile,
                    top100Prefix,
                    `${humanDesignDetailed}::bubble::other_${type}`,
                  )}
                />
              }
            >
              {type === 'types' ? '' : slug.replace('-', '/')} {title}
            </Typography>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={cn(s.bubblesWrapper, className)}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component="h2"
        className={s.title}
      >
        Другие {type === 'types' ? 'типы' : 'профили'}
      </Typography>
      {renderList()}
    </div>
  );
}

HumanDesignBubbles.defaultProps = {
  list: [],
  className: '',
  type: 'types',
};

export { HumanDesignBubbles };
