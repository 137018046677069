import React, { useState, useEffect } from 'react';

import {
  SubscribeTypes,
  SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
  SUBSCRIBE_POPUP_TIME_BEFORE_SHOW,
  SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY,
} from 'config/constants/news-letter';
import { sentryReactSend } from 'utils/sentry/client';

import SubscribePopup from '..';
import { isSubscribePopupShouldBeShown } from '../../utils';

interface SubscribePopupInLayoutPropsType {
  type: SubscribeTypes;
}

function SubscribePopupInLayout({ type }: SubscribePopupInLayoutPropsType) {
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (isSubscribePopupShouldBeShown(type)) {
      timerId = setTimeout(() => {
        setIsSubscribePopupOpen(true);
        window.localStorage.setItem(
          SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
          `${Number(new Date())}`,
        );
      }, SUBSCRIBE_POPUP_TIME_BEFORE_SHOW);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [type]);

  const handleSubscribePopupClose = () => {
    setIsSubscribePopupOpen(false);

    try {
      window.localStorage.setItem(
        `rh_${type}${SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY}`,
        `${Number(new Date())}`,
      );
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

  return (
    <SubscribePopup
      isOpen={isSubscribePopupOpen}
      onClose={handleSubscribePopupClose}
      type={type}
    />
  );
}

export default SubscribePopupInLayout;
