import { createContext, useContext } from 'react';

interface ContextType {
  top100Prefix: string;
}

export const Top100Context = createContext<ContextType>({
  top100Prefix: '',
});

export const useTop100Context = () => useContext(Top100Context);
