import React from 'react';
import cn from 'classnames';

import { Title } from 'common/components/Card/Title';
import { Form } from 'common/components/Card/Form';

import s from './styles.css';

interface IPersonalTarotTodayFormProps {
  isMobile: boolean;
}

function PersonalTarotTodayForm({ isMobile }: IPersonalTarotTodayFormProps) {
  return (
    <div className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop)}>
      <Title
        title="Ваша картина дня на&nbsp;сегодня"
        isMobile={isMobile}
        isH2Title
        type="form"
        isLink={false}
      />

      <Form
        form="personal_taro_today"
        isMobile={isMobile}
        className={cn(s.form, isMobile ? s.formMobile : s.formDesktop)}
      />
    </div>
  );
}

export { PersonalTarotTodayForm };
