/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';
import equal from 'fast-deep-equal/react';

import { YANDEX_METRICS } from 'config/constants/counters';

import { YandexEvent } from 'utils/counters/YandexEvent';
import { sentryReactSend } from 'utils/sentry/client';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { useNotificationsContext } from '../../context/notificationsContext';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [(state: IAppState) => state.runtime.isMobile],
  (isMobile) => ({
    isMobile,
  }),
);

const DAYS_IN_WEEK = 7;
const PWA_DELAY = 2 * DAYS_IN_WEEK * 24 * 60 * 60 * 1000; // 14 дней *

interface IPwaProps {
  className?: string;
}

function Pwa({ className }: IPwaProps) {
  const { isMobile } = useSelector(selectRuntimeData);
  const { setIsPwaClosed } = useNotificationsContext();
  const [isVisible, setIsVisible] = useState(false);
  const [safariByDevice, setSafariByDevice] = useState({
    iPad: false,
    iPhone: false,
  });
  // eslint-disable-next-line sonarjs/deprecation
  const deferredPrompt = useRef<BeforeInstallPromptEvent>();

  const sendPwaShowNotification = () => {
    const lastShown =
      window.localStorage.getItem('rh_pwaSubLastShownTime') || '0';
    const now = Number(new Date());

    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: 'notification_pwa_custom_show',
    });

    const newValue = now - PWA_DELAY >= Number(lastShown); // показываем не чаще чем раз в 2 недели

    if (isVisible !== newValue) {
      setIsVisible(newValue);
    }
  };

  useEffect(() => {
    const { userAgent } = window.navigator;
    const isChromium = !!window.chrome;
    // eslint-disable-next-line sonarjs/slow-regex
    const isIPhoneSafari = /iPhone.+Version\/[\d.]+.*Safari/i.test(userAgent);
    const isIPadSafari =
      /Macintosh/i.test(navigator.userAgent) &&
      Boolean(navigator.maxTouchPoints) &&
      navigator.maxTouchPoints > 1;
    const isAndroid = /android/i.test(userAgent);

    const newData = {
      iPad: isIPadSafari,
      iPhone: isIPhoneSafari,
    };

    if (!equal(safariByDevice, newData)) {
      setSafariByDevice(newData);
    }

    if (isIPadSafari || isIPhoneSafari) {
      sendPwaShowNotification();
    } else if (isChromium) {
      window.addEventListener(
        'beforeinstallprompt',
        // eslint-disable-next-line sonarjs/deprecation
        (e: BeforeInstallPromptEvent) => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          e.preventDefault();

          if (!isMobile || isAndroid) {
            // Stash the event so it can be triggered later.
            deferredPrompt.current = e;

            sendPwaShowNotification();
          }
        },
        { once: true },
      );

      window.addEventListener(
        'appinstalled',
        () => {
          // Hide the app-provided install promotion
          setIsVisible(false);
          // Clear the deferredPrompt so it can be garbage collected
          deferredPrompt.current = undefined;
          // Optionally, send analytics event to indicate successful install

          new YandexEvent(YANDEX_METRICS.COMMON).send({
            type: 'reachGoal',
            data: 'notification_system_instal',
          });

          setIsPwaClosed(true);
        },
        { once: true },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickSubmit = async () => {
    setIsVisible(false);

    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: 'notification_pwa_custom_accepted',
    });

    if (deferredPrompt.current) {
      // Show the prompt
      deferredPrompt.current.prompt();

      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: 'notification_system_show',
      });

      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.current.userChoice;
      // Optionally, send analytics event with outcome of user choice

      const ymText =
        outcome === 'accepted'
          ? 'notification_system_accepted'
          : 'notification_system_declined';

      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: ymText,
      });

      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt.current = undefined;

      if (outcome === 'accepted') {
        setIsPwaClosed(true);
      }
    } else {
      setIsPwaClosed(true);
    }
  };

  const onClose: (type: 'closed' | 'declined') => void = (type) => {
    // Hide the app-provided install promotion
    setIsVisible(false);
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt.current = undefined;
    // Optionally, send analytics event to indicate notification close

    try {
      window.localStorage.setItem(
        'rh_pwaSubLastShownTime',
        `${Number(new Date())}`,
      );
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }

    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: `notification_pwa_custom_${type}`,
    });

    setIsPwaClosed(true);
  };

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        isVisible && s.visible,
        safariByDevice.iPhone && isMobile && s.rootMobileBottom,
        className,
      )}
    >
      <Typography variant="defaultMedium" className={s.text}>
        Теперь Рамблер/гороскопы можно добавить на главный экран
      </Typography>
      <Icon
        id="close-small"
        className={cn(s.icon, s.iconClose)}
        onClick={() => onClose('closed')}
        {...getTop100Markup(isMobile, 'notification::pwa::closed')}
      />
      {safariByDevice.iPad || safariByDevice.iPhone ? (
        <Typography variant="defaultMedium" className={s.ios}>
          <span className={s.iosText}>
            Нажмите
            <Icon id="pwa-add" className={cn(s.icon, s.iconAdd)} />и выберите
          </span>
          <span className={s.iosText}>
            <Icon id="pwa-plus" className={cn(s.icon, s.iconPlus)} />
            На экран «Домой»
          </span>
        </Typography>
      ) : (
        <div className={s.buttons}>
          <Typography
            variant="defaultMedium"
            className={s.submit}
            element={<span onClick={onClickSubmit} />}
            {...getTop100Markup(isMobile, 'notification::pwa::accepted')}
          >
            Да, добавить
          </Typography>
          <Typography
            variant="defaultMedium"
            className={s.reject}
            element={<span onClick={() => onClose('declined')} />}
            {...getTop100Markup(isMobile, 'notification::pwa::declined')}
          >
            Отмена
          </Typography>
        </div>
      )}
    </div>
  );
}

Pwa.defaultProps = {
  className: '',
};

export { Pwa };
