import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { APP_THEME } from 'config/constants';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.adminData.settings.themes_disabled,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.runtime.isPremium,
    (state: IAppState) => state.account.theme,
    (state: IAppState) => state.account.sign,
  ],
  (
    isThemesDisabled,
    isBranding,
    isPremium,
    userTheme,
    sign,
    // eslint-disable-next-line max-params
  ) => ({
    isThemesDisabled,
    isBranding,
    isPremium,
    userTheme,
    sign,
  }),
);

export const useAppTheme = () => {
  const { isThemesDisabled, isBranding, isPremium, userTheme, sign } =
    useSelector(selectData);
  const prevClassName = useRef('');

  const theme = isPremium ? APP_THEME.White : userTheme;

  useEffect(() => {
    const { classList } = document.body;

    if (prevClassName.current) {
      classList.remove(prevClassName.current);
    }

    let className = '';

    if (!isBranding && (!isThemesDisabled || isPremium)) {
      className =
        theme === APP_THEME.Sign ? s[sign || 'noSign'] : s[APP_THEME[theme]];
    }

    if (className) {
      classList.add(className);
    }

    prevClassName.current = className;
  }, [theme, sign, isBranding, isThemesDisabled, isPremium]);
};
