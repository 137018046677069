import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.reloadKey],
  (reloadKey) => ({
    reloadKey,
  }),
);

export const useIncrementReloadKey = () => {
  const dispatch = useDispatch();
  const { reloadKey } = useSelector(selectData);

  return () =>
    dispatch({
      type: SET_RUNTIME_VARIABLE,
      name: 'reloadKey',
      value: reloadKey + 1,
    });
};
