import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { Link } from 'react-router-dom';

import { SIGN } from 'config/constants/sign';
import { signNames } from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.currentParams.zodiacSign,
    (state: IAppState) => state.runtime.currentParams.zodiacDescriptionType,
  ],
  (isMobile, zodiacSign, zodiacDescriptionType) => ({
    isMobile,
    zodiacSign,
    zodiacDescriptionType,
  }),
);

interface ICardSignMenuProps {
  top100Prefix: string;
  className?: string;
  type?: string;
}

function SignMenu({ top100Prefix, className, type }: ICardSignMenuProps) {
  const [expanded, setExpanded] = useState(false);
  const { isMobile, zodiacSign, zodiacDescriptionType } =
    useSelector(selectData);

  const zodiacTypeForDescription = zodiacDescriptionType
    ? `${zodiacDescriptionType}/`
    : '';

  const getLink = () => {
    switch (type) {
      case 'description':
        return `description/${zodiacTypeForDescription}`;
      case 'compatibility':
        return 'sovmestimost-znakov-zodiaka/';
      default:
        return '';
    }
  };

  const getLinkText = () => {
    switch (type) {
      case 'description':
        return 'характеристика знака';
      case 'compatibility':
        return 'совместимость знака';
      default:
        return '';
    }
  };

  return (
    <div
      className={cn(
        s.signMenu,
        isMobile ? s.signMenuMobile : s.signMenuDesktop,
        expanded && s.signMenuExpanded,
        className,
      )}
    >
      <div
        className={cn(
          s.list,
          isMobile ? s.listMobile : s.listDesktop,
          expanded && s.listExpanded,
        )}
      >
        {Object.keys(SIGN).map((sign: keyof typeof SIGN) => {
          const link = `/${sign}/description/${zodiacTypeForDescription}`;
          const activeSign = sign === zodiacSign;

          return (
            <Typography
              variant="defaultMedium"
              className={cn(
                s.signMenuItem,
                isMobile ? s.signMenuItemMobile : s.signMenuItemDesktop,
                s.defaultBubble,
                activeSign && s.defaultBubbleActive,
                s[sign],
                activeSign && s[`${sign}Active`],
              )}
              key={sign}
              element={
                <Link
                  to={`/${sign}/${getLink()}`}
                  {...getTop100Markup(
                    isMobile,
                    top100Prefix,
                    `bubble_menu::bubble_link::${link}`,
                  )}
                />
              }
            >
              {`${signNames.zodiac[sign]} — ${getLinkText()}`}
            </Typography>
          );
        })}
      </div>
      <Button
        type="white"
        className={s.showMore}
        onClick={() => setExpanded(!expanded)}
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          `bubble_menu::${expanded ? 'close' : 'open'}`,
        )}
      >
        <Icon
          id="arrow-down"
          className={cn(s.showMoreIcon, expanded && s.showMoreIconUp)}
        />
      </Button>
    </div>
  );
}

SignMenu.defaultProps = {
  className: '',
  type: '',
};

export { SignMenu };
