import React, { useState, useMemo } from 'react';
import cn from 'classnames';

import { HUMAN_DESIGN_STATIC_TEXT } from 'config/constants/humanDesign';

import { Typography } from 'common/components/Typography';

import { Form } from './Form';

import { DateByDeviceContext } from './contexts/dateByDeviceContext';

import s from './styles.css';

interface IHumanDesignForm {
  isMobile: boolean;
  isMainPage?: boolean;
  isWithTitle?: boolean;
  isExperimental: boolean;
  className?: string;
}

function HumanDesignForm({
  isMobile,
  isMainPage,
  isWithTitle,
  isExperimental,
  className,
}: IHumanDesignForm) {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const hdMaxYear = new Date().getFullYear() + 1;

  const contextMemo = useMemo(
    () => ({ isButtonClicked, setIsButtonClicked }),
    [isButtonClicked],
  );

  return (
    <DateByDeviceContext.Provider value={contextMemo}>
      <div
        className={cn(
          s.root,
          isMobile ? s.rootMobile : s.rootDesktop,
          isMainPage && s.rootMain,
          className,
        )}
      >
        {isWithTitle && (
          <h2
            className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}
          >
            Рассчитайте свой бодиграф
          </h2>
        )}
        <div
          className={cn(
            s.form,
            isMobile ? s.formMobile : s.formDesktop,
            isMainPage && s.formMain,
            isExperimental && s.formExperimental,
            s.formHumanDesignForm,
          )}
        >
          <Form
            maxYear={hdMaxYear}
            isMobile={isMobile}
            isExperimental={isExperimental}
          />
        </div>
        {isMainPage && (
          <>
            <h2
              className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}
            >
              Что такое Дизайн Человека?
            </h2>
            <Typography
              variant="defaultRegular"
              component="p"
              className={cn(s.text, isMobile ? s.textMobile : s.textDesktop)}
            >
              {HUMAN_DESIGN_STATIC_TEXT}
            </Typography>
          </>
        )}
      </div>
    </DateByDeviceContext.Provider>
  );
}

HumanDesignForm.defaultProps = {
  isMainPage: false,
  isWithTitle: true,
  className: '',
};

export { HumanDesignForm };
