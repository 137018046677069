import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Image from '@rambler-components/image';

import { useTop100Context, Top100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import {
  getSubscribePopupTypes,
  SubscribeTypes,
  SUBSCRIBE_POPUP_TYPE_SUCCESS_STORAGE_KEY,
} from 'config/constants/news-letter';

import { SubscribeForm } from '../../SubscribeForm';
import SuccessContent from '../../SubscribeSuccess/Content';

import s from './index.css';

interface IPopupContent {
  type: SubscribeTypes;
  onClose: () => void;
}

export function PopupContent({ type, onClose }: IPopupContent) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const { newsLetterCrmKeys } = useSelector(
    (state: IAppState) => state.runtime.config,
  );

  const [isSuccess, setIsSuccess] = useState(false);

  const { top100Prefix } = useTop100Context();

  const { title, description, icon, id } =
    getSubscribePopupTypes(newsLetterCrmKeys)[type];

  const top100PopupPrefix = useMemo(
    () => ({ top100Prefix: `${top100Prefix}::subscribe_popup::${id}` }),
    [type], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSubscribePopupSuccess = () => {
    setIsSuccess(true);
    window.localStorage.setItem(
      `rh_${type}${SUBSCRIBE_POPUP_TYPE_SUCCESS_STORAGE_KEY}`,
      '1',
    );
  };

  return (
    <div className={s.root}>
      {!isSuccess && (
        <>
          <div className={s.illustration}>
            <div className={s.wave} />
            <div className={s.icon}>
              <Image
                className={s.imageIcon}
                isS3={false}
                isImg
                src={`/news-letter/${icon}.png`}
                src2x={`/news-letter/${icon}@2x.png`}
              />
            </div>
          </div>
          <h3 className={s.title}>{title}</h3>
          <p className={s.description}>{description}</p>
          <Top100Context.Provider value={top100PopupPrefix}>
            <SubscribeForm
              subscribeKeys={[id]}
              onSuccess={handleSubscribePopupSuccess}
            />
          </Top100Context.Provider>
          <p className={s.agreement}>
            Нажимая на кнопку «Подписаться» вы соглашаетесь с условиями&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://help.rambler.ru/www/legal/1397/"
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                `subscribe_popup::${id}::agreement_link`,
              )}
            >
              пользовательского соглашения
            </a>
          </p>
        </>
      )}
      {isSuccess && (
        <Top100Context.Provider value={top100PopupPrefix}>
          <SuccessContent onClose={onClose} />
        </Top100Context.Provider>
      )}
    </div>
  );
}
