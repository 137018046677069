import React from 'react';
import cn from 'classnames';

interface IconProps {
  id: string;
  className?: string;
  style?: object;
  onClick?: () => void;
  [x: string]: any;
}

/**
 * Компонент вставки svg картинок.
 * Для смена цвета иконки необходимо установить fill="currentColor" и стилем задать color: $color.
 * Пример: <path fill="currentColor">
 *
 * @param id - имя файла с картинкой
 * @param className - дополнительный класс стилизации
 * @param style - дополнительные стили
 * @param onClick - событие по клику
 */
function Icon({ id, className, style, onClick, ...rest }: IconProps) {
  return (
    <svg
      className={cn(className)}
      pointerEvents={onClick ? 'auto' : 'none'}
      data-cy={id}
      style={style}
      onClick={onClick}
      {...rest}
    >
      <use xlinkHref={`#${id}`} />
    </svg>
  );
}

Icon.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
};

export { Icon };
