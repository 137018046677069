import { useCallback, useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
  apply: boolean,
) => {
  const onClickOutside = useCallback((e: MouseEvent) => {
    const { current } = ref;

    if (
      !current ||
      (typeof current.contains === 'function' &&
        !current.contains(e.target as HTMLElement))
    ) {
      callback(e);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-selector-parameter
    if (apply) {
      window.addEventListener('click', onClickOutside);
    } else {
      window.removeEventListener('click', onClickOutside);
    }
  }, [apply]); // eslint-disable-line
};
