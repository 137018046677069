import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import cn from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';

import Button from '@rambler-components/button';
import { snackbarEvent } from '@rambler-components/snackbar';

import Select from '@rambler-components/select';
import Search, { SuggestResponseType } from '@rambler-components/search';
import InputDate from 'common/components/InputDate';
import InputTime from 'common/components/InputTime';

import { safeGet } from 'utils/safeGet';

import { fetchHumanDesignGeoData } from 'common/redux/humanDesign';
import { fetchHumanDesignCitiesData } from 'utils/fetchSuggestedData';
import { checkTimeValue, getDisplayName } from 'common/utils/timeValue';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100 } from 'common/components/Card/Form/utils';
import { useDateByDeviceContext } from './contexts/dateByDeviceContext';

import s from './styles.css';

interface IFormProps {
  isMobile: boolean;
  isExperimental: boolean;
  maxYear: number;
}

const selectGeoData = createSelector(
  [
    (state: IAppState) => state.humanDesign.places.countries,
    (state: IAppState) => state.humanDesign.isGeoError,
  ],
  (countries, isGeoError) => ({
    countries,
    isGeoError,
  }),
);

const selectAccount = createSelector(
  [(state: IAppState) => state.account],
  (account) => ({
    account,
  }),
);

const selectRuntime = createSelector(
  [(state: IAppState) => state.runtime],
  (runtime) => ({
    runtime,
  }),
);

function Form({ isMobile, isExperimental, maxYear }: IFormProps) {
  const { countries, isGeoError } = useSelector(selectGeoData);

  const { account } = useSelector(selectAccount);

  const { runtime } = useSelector(selectRuntime);

  const { top100Prefix } = useTop100Context();
  const { isButtonClicked, setIsButtonClicked } = useDateByDeviceContext();

  const dispatch = useDispatch();
  const history = useHistory();

  const [dateValue, setDateValue] = useState('');
  const [timeValue, setTimeValue] = useState('');
  const [countryValue, setCountryValue] = useState('RU');
  const [cityValue, setCityValue] = useState<any>(null);
  const showGeoError = useRef(isGeoError);

  useEffect(() => {
    dispatch(fetchHumanDesignGeoData('places', 'countries'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsButtonClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, timeValue, countryValue, cityValue]);

  useEffect(() => {
    setCityValue(null);
  }, [countryValue]);

  useEffect(() => {
    showGeoError.current = isGeoError;
  }, [isGeoError]);

  useEffect(() => {
    if (account.birthday) {
      setDateValue(account.birthday);
    }

    if (account.birthtime) {
      setTimeValue(account.birthtime);
    }
  }, [account]);

  const getSuggestedCountriesBlock = useMemo(() => {
    const suggestItems = safeGet(() => countries, []);

    if (!suggestItems || !suggestItems.length) return [];

    return suggestItems
      .sort(
        (
          a: HumanDesignSuggestCountryType,
          b: HumanDesignSuggestCountryType,
        ) => {
          if (a.country_name < b.country_name) {
            return -1;
          }

          if (a.country_name > b.country_name) {
            return 1;
          }

          return 0;
        },
      )
      .map((suggestItem: HumanDesignSuggestCountryType) => ({
        label: suggestItem.country_name,
        value: suggestItem.country_iso,
      }));
  }, [countries]);

  const onSubmit = () => {
    setIsButtonClicked(true);

    if (showGeoError.current) {
      snackbarEvent({
        message: 'Что-то не выходит. Попробуйте ещё раз',
        type: 'error',
        align: 'bottom center',
        withCloseButton: true,
      });

      return;
    }

    if (
      cityValue &&
      dateValue &&
      timeValue &&
      countryValue &&
      checkTimeValue(timeValue)
    ) {
      const urlParams = `?date=${dateValue}T${getDisplayName(timeValue)}&timezone=${cityValue.timezone}&place=${cityValue.ind}`;

      history.push({
        pathname: `/dizain-cheloveka/karta/${urlParams}`,
        state: {
          loading: true,
        },
      });
    }
  };

  const onSuggest = useCallback(
    async (query: string): Promise<SuggestResponseType> => {
      // в query не должно быть ничего кроме названия населенного пункта
      const parseQuery = query.split(',')?.[0] || query;

      try {
        const data = await fetchHumanDesignCitiesData(
          { runtime },
          parseQuery,
          countryValue,
        );

        showGeoError.current = false;

        return data;
      } catch (error) {
        if (error.type === 'unavailable') {
          showGeoError.current = true;
        }
      }

      return {
        query,
        suggests: [],
      };
    },
    [countryValue, runtime],
  );

  const formAutocomplete = ({
    placeholder,
    top100Tail,
    status,
    value,
  }: {
    placeholder: string;
    top100Tail: string;
    status?: 'default' | 'warning' | 'error';
    value?: any;
  }) => (
    <Search
      className={cn(
        s.input,
        s.inputHumanDesign,
        s.inputHumanDesignAutocomplete,
        isMobile && s.inputMobile,
        isExperimental && s.inputExperimental,
        s[`input-${status}`],
      )}
      placeholder={placeholder}
      showSearchButton={false}
      type="border"
      display="normal"
      action=""
      value={value}
      onSuggestAction={onSuggest}
      onSubmit={(e, query, submitDetails) => {
        e.preventDefault();

        if (submitDetails) {
          setCityValue(submitDetails?.suggestItem);
        }
      }}
      onClear={() => {
        setCityValue(null);
      }}
      {...getTop100({
        isMobile,
        top100Prefix,
        form: 'human_design_form',
        tail: top100Tail || placeholder,
      })}
    />
  );

  const formSelect = ({
    suggest,
    placeholder,
    top100Tail,
    status,
    value,
    setValue,
  }: {
    suggest: any;
    placeholder: string;
    top100Tail: string;
    status?: 'success' | 'warning' | 'error';
    value?: any;
    setValue?: any;
  }) => (
    <Select
      className={cn(
        s.input,
        s.inputHumanDesign,
        s.inputHumanDesignAutocomplete,
        isMobile && s.inputMobile,
        isExperimental && s.inputExperimental,
      )}
      options={suggest}
      placeholder={placeholder}
      type="border"
      status={status}
      withSearch
      value={value}
      onChange={setValue}
      {...getTop100({
        isMobile,
        top100Prefix,
        form: 'human_design_form',
        tail: top100Tail || placeholder,
      })}
    />
  );

  const formInput = ({
    placeholder,
    top100Tail,
    type,
    status,
    value,
    setValue,
    topBound,
  }: {
    placeholder: string;
    top100Tail: string;
    type?: 'date' | 'time';
    status?: 'error' | 'warning' | 'success';
    value?: string;
    setValue?: any;
    topBound?: string;
  }) =>
    type === 'date' ? (
      <InputDate
        className={cn(
          s.inputHumanDesign,
          isMobile && s.inputMobile,
          isExperimental && s.inputExperimental,
        )}
        status={status}
        placeholder={placeholder}
        isMobile={isMobile}
        type="border"
        value={value}
        onChange={(date: string) => {
          setValue(date);
        }}
        max={topBound}
        {...getTop100({
          isMobile,
          top100Prefix,
          form: 'human_design_form',
          tail: top100Tail || placeholder,
        })}
      />
    ) : (
      <InputTime
        className={cn(
          s.inputHumanDesign,
          isMobile && s.inputMobile,
          isExperimental && s.inputExperimental,
        )}
        status={status}
        placeholder={placeholder}
        isMobile={isMobile}
        type="border"
        value={value}
        onChange={setValue}
        {...getTop100({
          isMobile,
          top100Prefix,
          form: 'human_design_form',
          tail: top100Tail || placeholder,
        })}
      />
    );

  const formButton = (onClick: () => void) => (
    <Button
      className={isMobile && s.buttonMobile}
      onClick={onClick}
      {...getTop100({
        isMobile,
        top100Prefix,
        form: 'human_design_form',
        tail: 'calculation_button',
      })}
    >
      Рассчитать
    </Button>
  );

  const formByType = [
    formInput({
      placeholder: 'Дата рождения',
      top100Tail: 'birthday',
      type: 'date',
      status: isButtonClicked && !dateValue ? 'error' : undefined,
      value: dateValue,
      setValue: setDateValue,
      topBound: `${maxYear}-12-31`,
    }),
    formInput({
      placeholder: 'Время рождения',
      top100Tail: 'time_of_birth',
      type: 'time',
      status: isButtonClicked && !timeValue ? 'error' : undefined,
      value: timeValue,
      setValue: setTimeValue,
    }),
    formSelect({
      suggest: getSuggestedCountriesBlock,
      placeholder: 'Страна рождения',
      top100Tail: 'country',
      status: isButtonClicked && !countryValue ? 'error' : undefined,
      value: countryValue,
      setValue: setCountryValue,
    }),
    formAutocomplete({
      placeholder: 'Город рождения',
      top100Tail: 'city',
      status:
        isButtonClicked && (!cityValue || !cityValue.descr)
          ? 'error'
          : undefined,
      value: cityValue ? cityValue.descr : '',
    }),
    formButton(onSubmit),
  ];

  return (
    <>
      {formByType.map((item: JSX.Element, index: number) => {
        const key = `Form-${index}`;

        return <Fragment key={key}>{item}</Fragment>;
      })}
    </>
  );
}

export { Form };
