import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { format, isWithinInterval } from 'date-fns';
import { createSelector } from 'reselect';

import Input, { InputDatetime } from '@rambler-components/input';
import { Dropdown, DropdownWrapper } from '@rambler-components/dropdown';
import '@rambler-components/dropdown/styles.css';
import Snackbar, { snackbarEvent } from '@rambler-components/snackbar';
import '@rambler-components/snackbar/styles.css';
import Button from '@rambler-components/button';
import Calendar from '@rambler-components/calendar';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import {
  getWithArgsSing,
  getWithArgsBirthdate,
} from 'config/constants/news-letter';

import { MIN_YEAR } from 'config/constants/calendar';

import { useNewsLetterSubscribe } from '../useNewsLetterSubscribe';

import s from './index.css';

const IOS_TIMEOUT = 300;

interface ISubscribeForm {
  isVisible?: boolean;
  subscribeKeys: string[];
  className?: string;
  isShortMobile?: boolean;
  onSuccess?: () => void;
  onFailed?: () => void;
  direction?: 'row' | 'column';
  inputType?: 'border' | 'fill';
}

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.userEmail,
    (state: IAppState) => state.account.birthday,
    (state: IAppState) => state.runtime.config.apiSubscription,
    (state: IAppState) => state.runtime.config.newsLetterCrmKeys,
  ],
  (
    isMobile,
    userEmail,
    birthday,
    apiSubscription,
    newsLetterCrmKeys,
    // eslint-disable-next-line max-params
  ) => ({
    isMobile,
    userEmail,
    birthday,
    apiSubscription,
    newsLetterCrmKeys,
  }),
);

export const SubscribeForm: React.FC<ISubscribeForm> = React.memo(
  ({
    subscribeKeys,
    isVisible = true,
    direction = 'column',
    inputType = 'fill',
    isShortMobile,
    onSuccess,
    onFailed,
    className,
  }) => {
    const inputDateRef = useRef<HTMLInputElement>();
    const inputMailRef = useRef<HTMLInputElement>();

    const {
      isMobile,
      userEmail,
      birthday,
      apiSubscription,
      newsLetterCrmKeys,
    } = useSelector(selectRuntimeData);

    // значения инпутов
    const [dateValue, setDateValue] = useState(birthday || '');
    const [emailValue, setEmailValue] = useState(userEmail || '');
    // открытие календаря
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    // ошибки инпутов
    const [emailError, setEmailError] = useState(false);
    const [dateError, setDateError] = useState(false);

    const { onFetchSubscribe, isLoading } = useNewsLetterSubscribe();

    const minDate = `${MIN_YEAR}-01-01`;
    const maxDate = format(new Date(), 'yyyy-MM-dd');

    const { top100Prefix } = useTop100Context();

    // если для подписки не нужна дата рождения - инпут не показываем
    const isVisibleInputDate =
      subscribeKeys.length === 1
        ? getWithArgsSing(newsLetterCrmKeys).includes(subscribeKeys[0]) ||
          getWithArgsBirthdate(newsLetterCrmKeys).includes(subscribeKeys[0])
        : true;

    useEffect(() => {
      setDateValue(birthday || '');
      setEmailValue(userEmail || '');
    }, [userEmail, birthday]);

    useEffect(() => {
      if (!isVisible) {
        if (inputMailRef?.current) {
          inputMailRef.current.blur();
        }

        if (inputDateRef?.current) {
          inputDateRef.current?.blur();
        }

        setIsOpenCalendar(false);
      }

      if (isMobile && isVisible) {
        // setTimeout нужен для ios
        // чтобы сначала открывался попап с формой
        //  а потом уже вызывалась клавиатура
        setTimeout(() => {
          if (inputMailRef?.current) {
            inputMailRef.current.focus();
          }
        }, IOS_TIMEOUT);
      }
    }, [isVisible, isMobile]);

    const onSubscribe = async () => {
      // проверка на заполненные поля
      if (!emailValue || (!dateValue && isVisibleInputDate)) {
        if (!emailValue) setEmailError(true);
        if (!dateValue) setDateError(true);

        return;
      }

      // простая проверка почты
      // eslint-disable-next-line sonarjs/slow-regex
      if (!/[a-z0-9-_]+@[a-z-_]+\.[a-z]{2,15}/.test(emailValue)) {
        setEmailError(true);

        return;
      }

      const result = await onFetchSubscribe({
        email: emailValue,
        birthday: dateValue,
        apiSubscription,
        subscribeKeys,
      });

      if (result.ok) {
        onSuccess?.();
      } else {
        snackbarEvent({
          message: 'Что-то не выходит. Попробуйте ещё раз',
          type: 'error',
          withCloseButton: true,
          align: 'bottom center',
          autoCloseDuration: 5000,
        });
        onFailed?.();
      }
    };

    const onChangeCalendar = (val: Date) => {
      setIsOpenCalendar(false);
      setDateValue(format(new Date(val), 'yyyy-MM-dd'));
      setDateError(false);
    };

    const onFocusInputDate = () => {
      if (!isMobile) {
        setIsOpenCalendar(true);
        setDateError(false);
      }
    };

    const onChangeInputDate = (date: Date) => {
      if (isMobile) {
        if (
          isWithinInterval(new Date(date), {
            start: new Date(minDate),
            end: new Date(maxDate),
          })
        ) {
          setDateValue(format(new Date(date), 'yyyy-MM-dd'));
          setDateError(false);
        } else {
          setDateError(true);
          setDateValue('');
        }
      }
    };

    const onChangeInputMail = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailValue(e.target.value);
      setEmailError(false);
    };

    const onKeyPressMail = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isMobile) {
        if (e.key === 'Enter') {
          inputDateRef?.current?.showPicker();

          if (inputDateRef?.current) {
            inputDateRef.current?.focus();
          }
        }
      }
    };

    return (
      <div
        className={cn(
          s.root,
          s[direction],
          isVisibleInputDate && !isShortMobile && s.visibleInputDate,
          className,
        )}
      >
        <Input
          forwardRef={inputMailRef as React.MutableRefObject<HTMLInputElement>}
          className={s.inputMail}
          type={inputType}
          inputType="email"
          status={emailError ? 'error' : undefined}
          placeholder="Электронная почта"
          value={emailValue}
          onChange={onChangeInputMail}
          autoComplete="email"
          onKeyDown={onKeyPressMail}
          {...getTop100Markup(
            isMobile,
            top100Prefix,
            'subscribe_form::input_email',
          )}
        />
        {isMobile && (
          <div className={s.inputDateWrapper}>
            <InputDatetime
              forwardRef={
                inputDateRef as React.MutableRefObject<HTMLInputElement>
              }
              inputType="date"
              className={s.inputDate}
              type={inputType}
              status={dateError ? 'error' : undefined}
              placeholder="Дата рождения"
              value={dateValue}
              onChangeDatetime={onChangeInputDate}
              min={minDate}
              max={maxDate}
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                'subscribe_form::input_birthdate',
              )}
            />
          </div>
        )}
        {!isMobile && (
          <DropdownWrapper className={s.inputDateWrapper}>
            <InputDatetime
              forwardRef={
                inputDateRef as React.MutableRefObject<HTMLInputElement>
              }
              inputType="date"
              className={s.inputDate}
              type={inputType}
              status={dateError ? 'error' : undefined}
              placeholder="Дата рождения"
              value={dateValue}
              onChangeDatetime={onChangeInputDate}
              onFocus={onFocusInputDate}
              min={minDate}
              max={maxDate}
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                'subscribe_form::input_birthdate',
              )}
            />
            <Dropdown
              maxWidth={300}
              minWidth={300}
              isOpen={isOpenCalendar}
              onClose={() => setIsOpenCalendar(false)}
            >
              <Calendar
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                isSelectable
                showYearSwitch
                onChange={onChangeCalendar}
              />
            </Dropdown>
          </DropdownWrapper>
        )}
        {!isShortMobile && (
          <Button
            isLoading={isLoading}
            onClick={onSubscribe}
            className={s.subscribeButton}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              'subscribe_form::subscribe_button',
            )}
          >
            Подписаться
          </Button>
        )}
        <Snackbar />
      </div>
    );
  },
);

SubscribeForm.displayName = 'SubscribeForm';
