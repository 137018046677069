import React, { useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { tarotList } from 'config/constants/tarot';

import { MenuItem } from './MenuItem';

import s from './styles.css';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.isStickyTopline],
  (isStickyTopline) => ({
    isStickyTopline,
  }),
);

interface ITarotMenuProps {
  isMobile: boolean;
  top100Prefix: string;
  isCompact: boolean;
}

const TarotMenu: React.FC<ITarotMenuProps> = React.memo(
  ({ isMobile, top100Prefix, isCompact }) => {
    const { isStickyTopline } = useSelector(selectData);
    const [expandedSlug, setExpandedSlug] = useState('');

    return (
      <div
        className={cn(
          s.root,
          isCompact && s.rootCompact,
          isStickyTopline && s.rootGap,
        )}
      >
        {tarotList.map((item) => (
          <MenuItem
            key={item.slug}
            items={item.cards}
            title={item.short}
            slug={item.slug}
            isMobile={isMobile}
            top100Prefix={top100Prefix}
            isCompact={isCompact}
            isExpanded={item.slug === expandedSlug}
            setExpandedSlug={setExpandedSlug}
          />
        ))}
      </div>
    );
  },
);

TarotMenu.displayName = 'TarotMenu';

export { TarotMenu };
