import { createContext, useContext } from 'react';

interface ContextType {
  isPwaClosed: boolean;
  setIsPwaClosed: (i: boolean) => void;
}

export const NotificationsContext = createContext<ContextType>({
  isPwaClosed: false,
  setIsPwaClosed: () => {},
});

export const useNotificationsContext = () => useContext(NotificationsContext);
