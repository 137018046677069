import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Image from '@rambler-components/image';
import { getCurrentTarot } from 'config/constants/tarot';

import s from './styles.css';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.origin],
  (origin) => ({
    origin,
  }),
);

interface ITarotCardPreviewProps {
  id: number | string;
  isMobile: boolean;
}

function TarotCardPreview({ id, isMobile }: ITarotCardPreviewProps) {
  const { origin } = useSelector(selectData);

  const currentCard = getCurrentTarot('id', id);
  const cardUrl = `${origin}/tarot/${id}.svg`;

  return (
    <div
      className={cn(s.wrapper, isMobile ? s.wrapperMobile : s.wrapperDesktop)}
    >
      <Image
        isImg
        alt={currentCard.name}
        className={cn(
          s.tarotCard,
          isMobile ? s.tarotCardMobile : s.tarotCardDesktop,
        )}
        src={cardUrl}
      />
    </div>
  );
}

export { TarotCardPreview };
