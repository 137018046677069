import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { VideoObject, Duration } from 'schema-dts';

import { safeGet } from 'utils/safeGet';
import { JsonLd } from 'utils/jsond';

import { cropImage } from 'common/utils/resizer';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.origin],
  (origin) => ({
    origin,
  }),
);

interface PropsType {
  cluster: ATCluster;
}

/**
 * Получение VideoObject для schema.org
 *
 * @params cluster - инфо о кластере
 */
export const VideoObjectSchema: React.FC<PropsType> = React.memo(
  ({ cluster }) => {
    const { origin } = useSelector(selectData);

    const {
      main_video: { details } = {},
      image,
      long_title: longTitle,
      body_short: bodyShort,
      publication_time: publicationTime,
    } = cluster;

    const clusterUrl = useMemo(() => {
      const { id, normalized_title: slug, topic } = cluster;

      const rubricAlias = safeGet(() => topic.alias, 'longread');

      return `${origin}/${rubricAlias}/${id}-${slug}/`;
    }, [cluster, origin]);

    const imageUrl = useMemo(
      () =>
        safeGet(
          () =>
            cropImage({
              src: image?.url || '',
              origin,
              width: 1280,
              height: 720,
            }),
          '',
        ),

      [origin, image],
    );

    return (
      <JsonLd<VideoObject>
        item={{
          '@context': 'https://schema.org',
          '@type': 'VideoObject',
          duration: safeGet(
            () => (details ? `T${Math.floor(details.duration / 1000)}S` : ''),
            '',
          ) as any as Duration,
          name: longTitle,
          description: bodyShort,
          uploadDate: publicationTime,
          url: clusterUrl,
          contentUrl: clusterUrl,
          embedUrl: clusterUrl,
          isFamilyFriendly: 'https://schema.org/True',
          thumbnail: {
            '@type': 'ImageObject',
            name: imageUrl,
          },
          thumbnailUrl: imageUrl,
        }}
      />
    );
  },
);

VideoObjectSchema.displayName = 'VideoObjectSchema';
