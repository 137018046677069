import React from 'react';

/**
 * Получение Organization schema.org для автора
 *
 * @returns - блок schema.org описывающий Organization
 */
export const Author: React.FC = React.memo(() => (
  <div itemProp="author" itemScope itemType="https://schema.org/Organization">
    <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
      <link itemProp="url" href="https://horoscopes.rambler.ru/logo.png" />
      <link
        itemProp="contentUrl"
        href="https://horoscopes.rambler.ru/logo.png"
      />
      <meta itemProp="width" content="386" />
      <meta itemProp="height" content="60" />
    </div>
    <meta itemProp="name" content="Рамблер/гороскопы" />
  </div>
));

Author.displayName = 'Author';
