import React, { MouseEvent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { MediaRawDraftContentState } from 'typings/rnews.Cluster';
import { generateChpu } from 'utils/generateChpu';
import { sentryReactSend } from 'utils/sentry/client';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import s from './styles.css';

interface ITableOfContentsProps {
  isMobile: boolean;
  draft: MediaRawDraftContentState | undefined;
  className?: string;
}

function TableOfContents({
  draft,
  isMobile,
  className,
}: ITableOfContentsProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const { top100Prefix } = useTop100Context();

  const items = useMemo(
    () =>
      draft?.blocks.reduce((acc: string[], item) => {
        if (item.type === 'header-two') {
          acc.push(item.text);
        }

        return acc;
      }, [] as string[]),
    [draft],
  );

  const toggleMenu = () => {
    setIsExpanded((prevValue) => !prevValue);
  };

  // Добавляем скролл по клику, тк если в урле есть параметры то дефолтное поведение скроллит наверх
  const scrollToElement = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const id = (event.target as HTMLAnchorElement).hash.slice(1);
    const h2Element = document.getElementById(id);

    if (h2Element) {
      h2Element.scrollIntoView({ behavior: 'smooth' });
    } else {
      sentryReactSend(new Error(`Элемент с id ${id} не найден.`));
    }
  };

  return Array.isArray(items) && items.length ? (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      <button className={s.button} type="button" onClick={toggleMenu}>
        Содержание
        <Icon
          id="arrow-next"
          className={cn(s.icon, isExpanded && s.iconExpanded)}
        />
      </button>

      {isExpanded && (
        <ul className={cn(s.list, isMobile ? s.listMobile : s.listDesktop)}>
          {items.map((title) => (
            <li key={title}>
              <Link
                to={`#${generateChpu(title)}`}
                onClick={scrollToElement}
                className={s.link}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'content_card::text_navigation',
                )}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null;
}

TableOfContents.defaultProps = {
  className: '',
};

export { TableOfContents };
