import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { format, isWithinInterval } from 'date-fns';

import { InputDatetime } from '@rambler-components/input';
import Calendar from '@rambler-components/calendar';

import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import {
  DATE_FORMAT,
  MIN_YEAR,
  CALENDAR_INIT_DATE,
} from 'config/constants/calendar';

import { IInputDate } from './types';

import s from './styles.css';

/**
 * Инпут даты/времени
 * @param value приходит в формате yyyy-MM-dd и выводится в формате dd.MM.yyyy
 */
function InputDate({
  value = '',
  status,
  disabled,
  isMobile,
  className = '',
  placeholder = '',
  type = 'border',
  display = 'normal',
  icon,
  min = `${MIN_YEAR}-01-01`,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  max = `${new Date().getFullYear() - 5}-01-01`,
  calendarInitDate,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: IInputDate) {
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [error, setError] = useState(status === 'error');

  const inputContainerNode = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    inputContainerNode,
    () => setCalendarOpened(false),
    calendarOpened,
  );

  const onChangeDate = (date: Date) => {
    setError(false);

    if (!date) {
      onChange('');

      return;
    }

    if (min && max) {
      if (
        !isWithinInterval(date, {
          start: new Date(min),
          end: new Date(max),
        })
      ) {
        setError(true);
        onChange('');

        return;
      }
    }

    onChange(format(new Date(date), DATE_FORMAT));
  };

  const onErrorDatetime = (err: string) => {
    if (err) {
      setError(true);
      onChange('');
    }
  };

  return (
    <div
      className={cn(s.root, isMobile && s.rootMobile)}
      ref={inputContainerNode}
    >
      <InputDatetime
        className={className}
        placeholder={currentPlaceholder}
        type={type}
        display={display}
        inputType="date"
        icon={icon}
        value={value}
        min={min}
        max={max}
        status={error ? 'error' : status}
        disabled={disabled}
        onChangeDatetime={onChangeDate}
        onErrorDatetime={onErrorDatetime}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setCurrentPlaceholder(placeholder);

          if (onBlur) onBlur(e);
        }}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          setCurrentPlaceholder('дд.мм.гггг');
          setCalendarOpened(false);
          if (onFocus) onFocus(e);
        }}
        onClickIcon={() => setCalendarOpened((prev) => !prev)}
        spellCheck="false"
        {...rest}
      />
      {!isMobile && calendarOpened && (
        <Calendar
          className={s.calendar}
          minDate={new Date(min)}
          maxDate={new Date(max)}
          initDate={
            value
              ? new Date(value)
              : new Date(calendarInitDate || CALENDAR_INIT_DATE)
          }
          value={new Date(value || CALENDAR_INIT_DATE)}
          showYear={false}
          showYearSwitch
          isSelectable
          onChange={(val: Date) => {
            onChangeDate(val);
            setCalendarOpened(false);
          }}
        />
      )}
    </div>
  );
}

export default InputDate;
