import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMatch } from 'date-fns';
import cn from 'classnames';

import Button from '@rambler-components/button';
import Select from '@rambler-components/select';

import { GENDER } from 'config/constants/gender';
import { YANDEX_METRICS } from 'config/constants/counters';

import { sendAccountData as reduxSendAccountData } from 'common/redux/account';

import InputDate from 'common/components/InputDate';
import InputTime from 'common/components/InputTime';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { YandexEvent } from 'utils/counters/YandexEvent';
import { checkTimeValue } from 'common/utils/timeValue';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { useTop100Context } from 'common/contexts/top100Context';

import { FieldHint } from './FieldHint';

import s from './styles.css';

const MIN_YEAR = 1910;

const initState = {
  birthday: '',
  birthtime: '',
  gender: GENDER.unknown,
};

export interface PersonalAccountPropsType {
  className?: string;
  isWithBubbles?: boolean;
  top100Prefix?: string;
}

interface FormValuesType {
  gender: AccountType['gender'];
  birthtime: string;
  birthday: string;
}

function PersonalAccount({
  className,
  isWithBubbles,
  top100Prefix: personalAccountTop100Prefix,
}: PersonalAccountPropsType) {
  const dispatch = useDispatch();
  const { top100Prefix } = useTop100Context();
  const currentAccount = useSelector((state: IAppState) => state.account);
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const rsid = useSelector((state: IAppState) => state.runtime.rsid);
  const currentPath = useSelector((state: IAppState) => state.runtime.path);

  /** Страница китайского гороскопа */
  const isChinese = currentPath.includes('/chinese/');

  const {
    birthday: accountBirthday,
    birthtime: accountBirthtime,
    gender: accountGender,
  } = currentAccount;

  const [formValues, setFormValues] = useState<FormValuesType>(initState);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = [
    {
      value: GENDER.unknown,
      label: 'Пол не определён',
    },
    {
      value: GENDER.male,
      label: 'Мужской',
    },
    {
      value: GENDER.female,
      label: 'Женский',
    },
  ];

  useEffect(() => {
    setFormValues((prevValues) => ({
      birthday: accountBirthday || prevValues.birthday,
      birthtime: accountBirthtime || prevValues.birthtime,
      gender: accountGender || prevValues.gender,
    }));
  }, [accountBirthday, accountBirthtime, accountGender]);

  const sendAccountData = async (data: AccountType) => {
    setLoading(true);
    await dispatch(reduxSendAccountData(data, true, true));
    setLoading(false);
    setOpened(false);

    if (!isChinese) {
      window.location.reload();
    }
  };

  const onSaveAccount = () => {
    const { birthday, birthtime, gender } = formValues;

    const acountNewData: AccountType = {
      ...currentAccount,
      gender,
    };

    if (rsid) {
      if (birthday && isMatch(birthday, 'yyyy-MM-dd')) {
        acountNewData.birthday = birthday;
      }

      if (birthtime && checkTimeValue(birthtime as string)) {
        acountNewData.birthtime = birthtime;
      }
    } else {
      acountNewData.birthday = birthday;
      acountNewData.birthtime = birthtime;
    }

    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: 'profile_birth_date_save',
    });

    sendAccountData(acountNewData);
  };

  const getTop100 = (tail: string) =>
    getTop100Markup(
      isMobile,
      top100Prefix || personalAccountTop100Prefix || '',
      `user_profile::${tail}`,
    );

  return (
    <div
      className={cn(
        s.root,
        isMobile && s.mobile,
        className,
        'personal-account',
      )}
    >
      <div
        className={cn(
          s.form,
          opened && s.open,
          isWithBubbles && s.formWithBubbles,
        )}
      >
        <button
          className={s.toggleButton}
          type="button"
          onClick={() => setOpened((prev) => !prev)}
        >
          <Typography variant="defaultBold" className={s.toggleButtonText}>
            Ваши данные для прогнозов
          </Typography>
          <Icon id="arrow-down" className={s.arrowIcon} />
        </button>
        <div className={s.info}>
          <div className={s.field}>
            <div className={s.label}>
              <Typography variant="smallMedium" className={s.labelText}>
                Дата рождения
              </Typography>
              <FieldHint type="birthday" />
            </div>
            <InputDate
              className={s.input}
              isMobile={isMobile}
              type="border"
              name="date"
              placeholder="Дата рождения"
              value={formValues.birthday}
              min={`${MIN_YEAR}-01-01`}
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              max={`${new Date().getFullYear() - 5}-01-01`}
              onChange={(value: string) => {
                setFormValues((prev) => ({
                  ...prev,
                  birthday: value,
                }));

                if (value) {
                  new YandexEvent(YANDEX_METRICS.COMMON).send({
                    type: 'reachGoal',
                    data: 'profile_birth_date_save',
                  });

                  if (!accountBirthday) {
                    new YandexEvent(YANDEX_METRICS.COMMON).send({
                      type: 'reachGoal',
                      data: 'lenta_birthday_input',
                    });
                  }
                }
              }}
              {...getTop100('birthday')}
            />
          </div>
          <div className={s.field}>
            <div className={s.label}>
              <Typography variant="smallMedium" className={s.labelText}>
                Время рождения
              </Typography>
              <FieldHint type="birthtime" />
            </div>
            <InputTime
              className={s.input}
              type="border"
              name="time"
              placeholder="Время рождения"
              value={formValues.birthtime}
              onChange={(value: string) => {
                setFormValues((prev) => ({
                  ...prev,
                  birthtime: value,
                }));
              }}
              {...getTop100('time_of_birth')}
            />
          </div>
          <div className={s.field}>
            <div className={s.label}>
              <Typography variant="smallMedium" className={s.labelText}>
                Пол
              </Typography>
              <FieldHint type="gender" />
            </div>
            <Select
              type="border"
              placeholder="Пол"
              className={s.select}
              value={formValues.gender}
              options={options}
              onChange={(value) => {
                setFormValues((prev) => ({
                  ...prev,
                  gender: value as AccountType['gender'],
                }));
              }}
            />
          </div>
          <Button
            className={s.saveButton}
            onClick={onSaveAccount}
            isLoading={loading}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
}

PersonalAccount.defaultProps = {
  className: '',
  top100Prefix: '',
  isWithBubbles: false,
};

export { PersonalAccount };
